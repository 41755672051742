/*btn*/
.pageBtn{width: 100%;height: 58px;display: block;background: var(--btnBg_0);border: 1px solid var(--btnBg_0);color: var(--btnTextColor);font-weight: 700;font-size: 14px;border-radius: 0;padding: 5px 10px;position: sticky;z-index:1;}
.pageBtn:disabled{background: var(--btnDisabledBg);color: var(--btnDisabledColor);cursor: auto;border-color: var(--btnDisabledLine)}
.pageBtn.subType{border-radius: 10px;height: 45px;font-size: 13px}
.pageBtn.line{background: rgba(115, 247, 202, 0.1);color: var(--btnBg_0)}
.pageBtn.line:disabled{background: #fff;border-color: var(--btnDisabledBg);color: var(--btnDisabledBg)}
.pageBtn.subType + .pageBtn.subType{margin: 12px 0 0}
.pageBtn.inputInBtn{margin-top: 30px}
.pageBtn.topMargin{margin-top: 20px}
.pageBtn.nonBtn{text-decoration: underline;background: transparent;border-color: transparent;height: 44px}
.pageBtn.snsBtn{border-radius: 10px;padding: 0 50px;border:0}
.pageBtn.col2{width: calc(50%)}
.pageBtn.col2:last-child{background: #124EA8}
.pageBtn.workHistory:disabled:first-child{background: var(--btnBg_0) !important;border: none !important;border-color: revert !important;}
.pageBtn.workHistory:disabled:last-child{background-color: #124EA8 !important;border: none !important;border-color: revert !important;}
a.pageBtn{display: flex;align-items: center;justify-content: center;text-align: center}

.snsBtn{width: 100%;height: 45px;display: block;border: 1px solid transparent;color: #fff;font-weight: 700;font-weight: 700;font-size: 13px;border-radius: 10px;padding: 10px 50px;position: relative}
.snsBtn.kakao{background: #FEEB00;border-color: #FEEB00;color: #000}
.snsBtn.apple{border-color: #000000;color: #000}
.snsBtn>img{position: absolute;top: 50%;transform: translateY(-50%);left: 15px}

.btn_box.col2{display: flex;justify-content: space-between;}
.pageBtn_box{padding: 0;background: transparent;position: fixed;left: 0;bottom: 0;width: 100%;z-index: 20}
.pageBtn_box.col2,.btn_subBox.col2{display: flex;justify-content: space-between}
.chk_item + .btn_subBox,.btnTextType + .btn_subBox{margin: 20px 0 0}
.pageBtn_box.col2>div{width:50%}
.pageBtn_box.col2>div:nth-child(1) .pageBtn{background: #404244;border-color: #404244;}

.btnTextType{color: #838383;font-size: 13px;font-weight: 700;margin: 0 0 10px;text-align: center}

.toggleBox{width: 35px;min-width: 35px}
.toggleBox label{cursor: pointer;display: flex;font-weight: 500;align-items: center;justify-content: space-between}
.toggleBox label span{width: 35px;height: 20px;background: #464650;border-radius: 23px;position: relative;transition: background 0.3s}
.toggleBox label span::before{content: "";position: absolute;top: 50%;transform: translateY(-50%);left: 2px;background: rgba(255, 255, 255, 0.8);border-radius: 100%;width: 16px;height: 16px;box-shadow: 0 0 3px 0 rgba(0,0,0,0.25);transition: left 0.3s cubic-bezier(0.89, 0.11, 0.09, 0.89)}
.toggleBox .toggleChk:checked + label span{background: #44D4A3}
.toggleBox .toggleChk:checked + label span::before{left: calc(100% - 18px)}

.textBtnItemBox{text-align: center}
.textBtn{font-size: 13px;font-weight: 600;color: #44D4A3;text-align: right}
.textBtn.underLine .textBtn_contents{text-decoration: underline}