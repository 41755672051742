@keyframes err{
  0%,
  100%{
    transform: translateX(0);
 }

  12.5%{
    transform: translateX(12px);
 }

  25%{
    transform: translateX(-12px);
 }

  37.5%{
    transform: translateX(8px);
 }

  50%{
    transform: translateX(-8px);
 }

  62.5%{
    transform: translateX(5px);
 }

  75%{
    transform: translateX(-5px);
 }

  87.5%{
    transform: translateX(3px);
 }
}

@keyframes spin{
  0%{
    transform: rotate(0);
 }
  100%{
    transform: rotate(360deg);
 }
}

@keyframes spinBanner{
  0%{
    transform: translateX(-50%) rotate(0);
 }
  100%{
    transform: translateX(-50%) rotate(360deg);
 }
}

@keyframes inputIn{
  0%{
    display: block;
  }
  100%{
    opacity: 1;display: block;font-size: 11px;top: 2%;transform: translate(0,2px);
  }
}

@keyframes inputOut{
  0%{
    display: block;
  }
  99%{
    display: block;
  }
  100%{
      display: none;
  }
}

@keyframes upTitleBox{
  from {
    z-index: 3;
  }

  to {
    z-index: 1;
  }
}

@keyframes filpUp{
  0% {
    transform: rotateX(0deg);
  }

  25% {
    transform: rotateX(-90deg);
  }

  100% {
    transform: rotateX(-90deg);
  }
}

@keyframes filpDown{
  0% {
    transform: rotateX(90deg);
  }

  25% {
    transform: rotateX(0deg);
  }

  100% {
    transform: rotateX(0deg);
  }
}
input[type='date']::-webkit-calendar-picker-indicator {position: absolute;left: 0;top: 0;width: 100%;height: 100%;background: transparent;color: transparent;cursor: pointer;}
input::-webkit-date-and-time-value {text-align: left;}

/*color*/
.dangerTextColor{color: #FF5F5F !important;}
.blueTextColor{color: #0094FF !important;}
.gTextColor{color:var(--mainColor) !important}
.gTextUnderline{text-decoration : underline;text-decoration-color: var(--mainColor);}
.boldText{font-weight: 700 !important}
.semiBoldText{font-weight: 600 !important}
.rTextColor{color:var(--rTextColor) !important}
.rBg{background-color:var(--rBgColor) !important}
.bgWhite{background: #FFF !important;}

/*img*/
.backgroundImg{background-repeat: no-repeat;background-position: center;background-size: cover}

/*btn*/
.btnDarkGray{background: #404244;border-color: #404244;}

/*gap*/
.grip-gap{gap: 10px;display: grid;}

/*hr*/
.hr{width: 100%;height: 1px;margin: 3px 0 17px 0;}
.grayHr{border: 1px dashed #E7ECF2;}
.darkGrayHr{border: 1px dashed #5F656B;margin-top: 12px;opacity: 0.7;}

/*flex*/
.flex{display: flex}

/*margin*/
.margin-right-5{margin-right: 5px}
.margin-left-5{margin-left: 5px}

/*padding*/
.padding-right-10{padding-right: 10px;}

/*header*/
.header{position: fixed;top: 0;left: 0;width: 100%;height: 54px;z-index: 1000}
.headerItem{cursor:pointer;margin: 16px 20px 16px 20px;display: flex;flex-direction: column;align-items:flex-start;gap: 16px;}
.headerItem .title{color: #191B1D;font-size: 14px;font-weight: 600;line-height: 14px;width: 80%;}
.headerItem .subItem{display: flex;padding: 14px 15px;align-items: flex-start;gap: 10px;align-self: stretch;border-radius: 8px;background: #FFF;justify-content: space-between;}
.headerItem .contents{color: #191b1d;font-size: 13px;font-weight: 500;line-height: 13px;text-align: right;}
.subHeaderCardBox{margin: 0 20px 0 20px;display: flex;gap: 12px;}
.subHeaderCard {margin-top: -5px !important;margin-bottom: 20px !important;}
.headerSection{height: 100%;padding: 0 20px;position: relative;background: #fff}
.headerSection.notBg{background: transparent}
.btnHeaderRight{position: absolute;right: 20px;top: 50%;transform: translateY(-50%)}
.btnHeaderRightItem{display: block;color: var(--mainTextColor);text-decoration: underline;font-size: 13px;font-weight: 600}
.btnHeaderLeft{position: absolute;left: 20px;top: 50%;transform: translateY(-50%);display: flex;gap: 12px;}
.headTitle{font-size: 13px;font-weight: 600;padding: 0 35px;display: flex;width: 100%;height: 54px;align-items: center;gap: 4px}
.headTitle.centerTitle{text-align: center;justify-content: center}
.btnHeaderRightLink{font-size: 13px;font-weight: 300;color: #ACACAC;text-decoration: underline;text-align: right}

/*editerBox*/
.editerBox *{font-weight: revert;color: revert;font-family: revert;font-size: revert;line-height: revert;white-space: pre-wrap}
.editerBox img{max-width: 100%}

/*popup*/
.popover{display: none;padding: 10px;position: absolute;justify-content: center;align-items: center;gap: 10px;border-radius: 6px;color: #FFF;font-size: 13px;font-weight: 500;line-height: 13px;}

.menuPopup{background: #404244;top: 120%;left: -26%;width: 92px;}
.menuPopup.work{left: -65% !important;}
.menuPopup.work.modify{left: 30% !important;}
.menuPopup.work.modify.result{left: 31% !important;}
.menuPopup.active{display: inline-flex;}

.labelPopupArrow{top: 160px;left: 105px;position: absolute;}
.labelPopup{display: inline-flex;background: #0094FF;top: 168px;left: 75px;}

.labelPopupBox{display: none;}
.menuButton .labelPopupButton:hover ~ .labelPopupBox{display: inline-flex;}

/*bottomPopup*/
.bottomPopup_optionBoxWrapper.active{position: fixed;top: 0;left: 0;width: 100%;height: 100%;background-color: rgba(0, 0, 0, 0.57);z-index: 1001;transition: all 0.3s;}
.bottomPopup_optionBox{display: none;width: 100%;border-radius: 30px 30px 0 0;flex-direction: column;align-items: center;gap: 6px;z-index:99;bottom: 0;left: 0;background: #FFF;position: fixed;border: 1px solid #FFF;color: var(--btnTextColor);}
.bottomPopup_optionBox.active{display: flex;}
.bottomPopup_optionBox .textBox {padding: 30px 0 24px 0;}
.bottomPopup_optionBox .title {color: #191B1D;text-align: center;font-size: 20px;font-weight: 700;line-height: 30px;letter-spacing: -0.2px;margin-bottom: 6px;}
.bottomPopup_optionBox .subTitle {color: #404244;text-align: center;font-size: 14px;font-weight: 400;line-height: 160%;letter-spacing: -0.42px;}
.bottomPopup_optionBox .buttonBox {display: flex;align-items: flex-start;width: 100%;}
.bottomPopup_optionBox .buttonBox .button {display: flex;width: 100%;height: 57px;justify-content: center;align-items: center;gap: 4px;background: #0094FF;color: #FFF;font-size: 15px;font-weight: 700;line-height: 15px;}
.bottomPopup_optionBox .buttonBox.col2 .button:first-child {background: #404244;}
.bottomPopup_optionBox .buttonBox.col2 .button {width: 50%;}

/*bottomErrMsg*/
.bottomErrMsg{position: fixed;bottom: 100px;transform: translateX(-50%);max-width: 400px;text-align: center;padding: 9px 18px;color: var(--white, #FFF);font-size: 13px;font-weight: 500;opacity: 0;background: rgba(11, 11, 12, 0.70);border-radius: 8px;left: 50%;width: calc(100% - 40px);display: none;transition: opacity 0.2s;z-index: 9999}
.bottomErrMsg.active{opacity: 1}

/*loarding*/
.loadingBox{position: fixed;top: 0;left: 0;width: 100%;height: 100%;z-index: 9999;display: none}
.loadingBox.active .popup_cover{transition: opacity 0s;opacity: 1}
.loading{position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%)}

/*err*/
.pageErrBox{text-align: center;padding: 70px 20px}
.pageErrTitle{font-size: 22px;font-weight: 700}
.pageErrText{margin: 14px 0 0;font-size: 14px}

/*icon*/
.icon{position: relative;width: 24px;height: 24px;}

/*page*/
.pageMainBg{background: #F5F7FB;}
.pageSubHeaderBox{width: 100%;padding: 20px 20px 30px;gap: 8px;align-items: center;display: inline-flex;position: relative;touch-action: none;}
.pageSubHeaderTitle{color: #1E1E1E;font-size: 18px;font-weight: 700;line-height: 18px;position: relative;width: fit-content;}
.pageSubHeaderSubTitle{color: #404244;font-size: 14px;font-weight: 400;line-height: 160%;letter-spacing: -0.42px;margin-top: 6px;}
.pageBody{background-color: #ffffff;border-radius: 30px 30px 0px 0px;height: calc(100vh - 54px);}
.pageTitleBox{width: 100%;padding: 20px 20px 30px;}
.pageTitle{color: var(--mainTextColor);font-size: 20px;font-weight: 600}
.pageSubTitle{font-weight: 500;margin: 4px 0 0;color: var(--mainTextColor);font-size: 13px}
.pageSubTitleBtn{color: var(--mainTextColor);font-size: 13px;font-weight: 500;margin: 14px 0 0;text-decoration: underline}

/*date*/
.selectYearBox{display: flex;width: 100%;align-items: center;padding: 20px 15px 20px 15px;justify-content: space-between;}
.selectYearText{font-size: 15px;font-weight: 700;line-height: 15px;text-align: center;}

.weekTitle{color: #3A3A3A;text-align: center;font-size: 15px;font-weight: 700;line-height: 15px;padding-top: 24px;}
.weekBox{width: 73%;height: 64px;flex-shrink: 0;display: flex;}
.weekItem{display: flex;width: 14%;padding: 9px 0px;flex-direction: column;align-items: center;gap: 6px;}
.weekItem.active{border-radius: 32px;border: 1px solid #5F656B;background: rgba(245, 247, 251, 0.50);}
.weekItemDayOfWeek{align-self: stretch;color: #959595;text-align: center;font-size: 12px;font-weight: 500;line-height: 12px;}
.weekItemDay{align-self: stretch;color: #000;text-align: center;font-size: 16px;font-weight: 700;line-height: 16px;}

/*select*/
.searchSelectBox{display: inline-flex;padding: 8px 8px 8px 12px;flex-direction: column;align-items: flex-start;gap: 12px;border-radius: 10px;border: 1px solid #000;background: #FFF;box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);width: 100%;margin-top: 12px;height: 140px;overflow: auto;overflow-x: hidden;overflow-y: scroll;}
.searchSelectItem{color: #191B1D;font-size: 14px;font-weight: 400;line-height: 160%;cursor: pointer;width: 100%;}

/*button*/
.beforeAfterButton{width: 24px;height: 24px;padding:1px;display: flex;align-items: center;justify-content: center;}
.headerRightButton{color: #0094FF;text-align: right;font-size: 14px;font-weight: 500;line-height: 14px;}

/*tag*/
.categoryTag{display: flex;padding: 6px;justify-content: center;align-items: center;gap: 10px;border-radius: 4px;text-align: center;font-size: 12px;font-weight: 600;line-height: 12px;font-style: normal;margin-left: 5px;min-width: fit-content;}
.categoryTag:first-child{margin-left: 0;}
.greenTag{background-color: #E7FFE1;color: #58A827}
.blueTag{background-color: #e1efff;color: #0094FF}
.redTag{background-color: #FFE9E9;color: #F00}
.yellowTag{background-color: #FFF5E2;color: #FFA800}
.grayTag{background-color: #F5F7FB;color: #5F656B}

.positionTagBox{display: flex;padding-top: 14px;gap: 10px;border-radius: 4px;text-align: center;font-size: 12px;font-weight: 600;line-height: 12px;flex-direction: row;flex-wrap: wrap;overflow: auto;overflow-x: hidden;overflow-y: scroll;}
.positionTag{display: flex;padding: 10px 14px;justify-content: center;align-items: center;border-radius: 42px;background: #0094FF;color: #FFF;gap: 10px;}

/*card*/
.cardBox{padding-bottom: 10px;overflow: auto;width: 100%;height: 100%;overflow-y: scroll;overflow-x: hidden;padding-bottom: 5px;}
.card{padding: 20px 16px;cursor:pointer;background: #FFF;border-radius: 14px;box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.07);margin: 16px 20px 16px 20px;display: flex;flex-direction: column;align-items:flex-start;gap: 16px;}
.card.bottomButton{border-radius: 14px 14px 0px 0px;margin-bottom: 0;box-shadow: 0px -1px 9px 0px rgba(0, 0, 0, 0.07);}
.card .subTitleLabel{color: #191B1D;font-size: 20px;font-weight: 600;line-height: 20px;}
.card.col2{width: 50%;margin: 0;}

.documentDetail_cardArea .document-card{margin: 0}
.document-card{display: flex;box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);margin: 16px 20px 16px 20px;background: #FFF;justify-content: space-between;border-radius: 14px;}
.document-card .titleBox{display: flex;justify-content: flex-start;gap: 6px;color: #191B1D;align-items: center;}
.document-card .titleBox .name{font-size: 15px;font-weight: 600;line-height: 15px;}
.document-card .contents{padding: 18px 16px;width: 75%;flex-direction: column;display: flex;align-items: flex-start;gap: 7px;border-right: 1px solid #E7ECF2;}
.document-card .contents.notBtn{border: 0;width: 100%}
.document-card .button{display: flex;justify-content: center;align-items: center;width: 25%;padding: 20px;font-size: 14px;font-weight: 600;line-height: 14px;}
.document-card .button.gray{color: #BDC6CF;}
.document-card .button.blue{color: #0094FF;}
.document-card .label{color: #909AA4;font-size: 12px;font-weight: 400;line-height: 12px;}

.cardButton{display: flex;align-items: flex-start;border-radius: 0 0 14px 14px;border-top: 1px solid #E7ECF2;margin: 0 20px 16px 20px;box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.07);}
.cardButton .button{display: flex;justify-content: center;font-size: 14px;font-weight: 600;line-height: 100%;letter-spacing: -0.028px;padding: 16px;}
.cardButton .button{width: 100%;}
.cardButton .button.col2{width: 50%;}
.cardButton .button.col2:first-child{border-right: 1px solid #E7ECF2;color: #0094FF;}
.cardButton .button.danger{color: #FF5F5F;}
.cardButton .button.complete{color: #BDC6CF;}

.card.todo{display: flex;align-items: center;gap: 12px;flex-direction: row;}
.card.todo.active{background: #E7ECF2;}
.card.todo.modal{margin: 16px 3px 16px 3px;}
.todoDateLabel{color: #909AA4;font-size: 13px;font-weight: 600;line-height: 13px;margin: 16px 20px 10px 20px;}
.todo .title{color: #191B1D;font-size: 15px;font-weight: 600;line-height: 15px;text-align: left;}
.todo .contents{color: #909AA4;font-size: 12px;font-weight: 400;line-height: 12px;}
.todo .contentsBox{display: grid;gap: 6px;width: 80%;}
.todo .contentsBox.active .title{text-decoration: line-through;}
.todo .alarmBox{display: grid;gap: 6px;color: #0094FF;text-align: center;font-size: 12px;font-weight: 600;line-height: 12px;}
.todo .alarmBox img{margin: 0 auto;}

.boardTitleBox{display: flex;flex-direction: column;align-items: flex-start; gap: 8px;}
.boardTitle{font-size: 15px;font-style: normal;font-weight: 600;line-height: 24px;color: #1E1E1E;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;text-overflow: ellipsis;max-height: 3em;word-break:break-all;}
.boardDate{color: #909AA4;font-size: 12px; font-weight: 400;line-height: 12px;}
.boardContents{flex-direction: column;align-items: flex-start;gap: 10px;align-self: stretch;font-size: 13px;font-weight: 400;line-height: 20px;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;text-overflow: ellipsis;max-height: 3em;white-space: pre-line;}
.boardContents *{word-break:break-all;color: #5F656B;}
.boardDetailContents{padding: 30px 20px 30px 20px;}
.boardDetailContents *{color: #5F656B;font-size: 13px;font-weight: 400;line-height: 20px;}

.cardNameBox{display: flex;align-items: center;gap: 6px;color: #191B1D;font-size: 14px;font-weight: 500;line-height: 14px;}
.cardEmployeeBox{display: flex;flex-direction: column;align-items: flex-start;gap: 6px;align-self: stretch;}
.cardEmployeeBox .title{color: #191B1D;font-size: 18px;font-weight: 700;line-height: 18px;}
.cardEmployeeBox .contents{color: #909AA4;font-size: 10px;font-weight: 400;line-height: 10px;}

.cardTitleBox{display: flex;align-items: center;gap: 16px;align-self: stretch;justify-content: space-between;}
.cardTitleBox .title{color: #191B1D;font-size: 14px;font-weight: 600;line-height: 14px;}
.cardTitleBox .title.tag{display: flex;flex-direction: column;align-items: flex-start;gap: 10px;}
.cardTitleBox .title.tag .name{color: #1E1E1E;font-size: 16px;font-weight: 600;line-height: 16px;}
.cardTitleBox .title.tag .name .sub{font-weight: 400;}
.cardTitleBox .menuBox{display: flex;align-items: flex-start;position: relative;}
.cardTitleBox .titleLabel, .headerItem .label{color: #909AA4;font-size: 13px;font-weight: 400;line-height: 13px;}
.cardTitleBox .titleLabel .titleBox .title{color: #1E1E1E;font-size: 14px;font-weight: 500;line-height: 18px;}
.cardTitleBox .titleLabel .titleBox .subTitle{color: #C3C3C3;font-size: 12px;font-weight: 500;line-height: 18px;}
.cardContentsBox{display: flex;flex-direction: column;align-items: flex-start;gap: 6px;align-self: stretch;width: 100%;}
.cardContentsBox .memo{color: #5F656B;font-size: 13px;font-weight: 400;line-height: 20px;}
.cardContentsBox .item{display: flex;width: 100%;padding: 14px 15px;align-items: flex-start;gap: 10px;align-self: stretch;border-radius: 8px;background: #F5F7FB;justify-content: space-between;}
.cardContentsBox .item .label{color: #909AA4;font-size: 13px;font-weight: 500;line-height: 13px;}
.cardContentsBox .item .contents{color: #191B1D;text-align: right;font-size: 13px;font-weight: 500;line-height: 13px;}

.cardContentsBox .largeItem{display: grid;padding: 14px 15px;gap: 12px;border-radius: 8px;background: #F5F7FB;width: 100%;}
.cardContentsBox .largeItem .label{color: #909AA4;font-size: 13px;font-weight: 500;line-height: 13px;}
.cardContentsBox .largeItem .contents{display: flex;align-items: flex-start;gap: 50px;align-self: stretch;color: #191B1D;font-size: 13px;font-weight: 500;line-height: 13px;}

.cardContentsBox .largeItem .contents.space{justify-content: space-between;}
.cardContentsBox .largeItem .contents.space .title{color: #909AA4;}

.fileBox{width: calc(100% - 40px);margin: 0 auto;align-items: center;background: #FFF;border-radius: 10px;display: flex;justify-content: center;gap: 10px;padding: 15px 16px;position: relative;box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.05);}
.fileInfo{display: flex;flex-direction: column;align-items: flex-start;gap: 6px;align-self: stretch;width: 85%;}
.fileName{align-self: stretch;color: #191B1D;font-size: 15px;font-weight: 600;line-height: 15px;}

.fileSize{align-self: stretch;color: #909AA4;font-size: 10px;font-weight: 400;line-height: 10px; }

.customFileBox{position: relative}
.customFileBox .inputItem{color: var(--placeholder);padding-right: 34px;display: flex;width: 100%;justify-content: space-between;}
.customFileBox .inputItem.active{color: var(--mainTextColor);}
.customFileBox::after{content: "";width:24px;height:24px;position: absolute;background: url(/assets/images/icon/fileUpload.svg) no-repeat center;top: 50%;transform: translateY(-50%);right: 12px}
.customFileBox .fileTitle{width: 85%;}

/*documentSign*/
.documentDetail_cardArea{padding: 20px}
.documentDetail_section{padding: 30px 30px 40px;height: 100%}
.signImg_viewBox{border: 1px solid #E7ECF2;position: relative}
.signImg_viewBox img{max-width: 100%}
.btn_image_view{position: absolute;bottom: 14px;right: 14px}

/*contentsSection*/
.contentsSection{width: 100%}
.contentsSection.bottomContents{display: flex;align-items: center;flex-direction: column;justify-content: space-between;min-height: calc(var(--vh, 1vh) * 100)}
.contentsSection.headerContents{padding-top: 54px}
.contentsSection.btnContents{padding-bottom: 40px}
.contentsSection.footerContents{padding-bottom: 60px}
.contentsSection.headerTapContents{padding-top: 98px}
.contentsSection.bottomBasicPadding{padding-bottom: 50px}

.headerContents .menu{display: flex;align-items: center;gap: 6px;padding-bottom: 20px;padding-left: 20px;}
.headerContents .menu .menuButton{display: flex;padding: 8px 10px;align-items: flex-start;gap: 4px;border-radius: 35px;background: #D5DCE3;color: #FFF;font-size: 13px;font-weight: 600;line-height: 13px;}
.headerContents .menu .menuButton.active{background: #404244;}

.itemSection{padding: 20px}
.itemSection + .itemSection{border-top: 6px solid #303036}
.itemSection.notPaddingTop{padding-top: 0}
.itemSection.notPaddingSide{padding-left: 0;padding-right: 0}
.itemSection + .itemSection.minLine{border-width: 1px}

/*tap*/
.tapBox{height: 100%;display: flex;align-items: center;gap: 14px;overflow-x: auto}
.tapItem{height: 100%;white-space: nowrap;font-size: 14px;color: #6E6E77;font-weight: 500;border-bottom: 2px solid transparent}
.tapItem.active{font-weight: 700;border-color: var(--mainTextColor);color: var(--mainTextColor)}
.tapBox.heightSet{height: 44px}

.tapBox.headerComTap{width: 100%;position: absolute;top: 54px;left: 0;background: #19191B;height: 44px;gap: 0;overflow-x: visible;border-bottom: 1px solid #303036}
.tapBox.headerComTap .tapItem{padding: 0 10px;width:100%}
.tapBox.headerComTap .tapItem:first-child:nth-last-child(2),.tapBox.headerComTap .tapItem:first-child:nth-last-child(2) ~ .tapItem{width: 50%}
.tapBox.headerComTap .tapItem:first-child:nth-last-child(3),.tapBox.headerComTap .tapItem:first-child:nth-last-child(3) ~ .tapItem{width: 33.33%}
.tapBox.headerComTap .tapItem:first-child:nth-last-child(4),.tapBox.headerComTap .tapItem:first-child:nth-last-child(4) ~ .tapItem{width: 25%}

.subTapBox{display: flex;align-items: center;gap: 4px;overflow-x: auto;padding: 15px 20px}
.subTapItem{border-radius: 100px;color: #6E6E77;font-size: 13px;padding: 7px 16px;white-space: nowrap}
.subTapItem.active{background: #303036;font-weight: 600;color: var(--mainTextColor)}

.popupTapBox{width: calc(100% - 30px);display: flex;align-items: center;justify-content: flex-start;gap: 14px;}
.popupTapItem{font-size: 16px;font-weight: 700;color: #6E6E77;}
.popupTapItem.active{color: var(--mainTextColor);}

/*notItem*/
.notItemArea{display: flex;flex-direction: column;align-items: center;justify-content: center;gap: 8px;text-align: center;height: 100%;width: 100%}
.notItemArea.min{height:auto;padding: 30px 0}
.notItemArea.fullPage{min-height: 60vh}
.notItemText{font-size: 13px;font-weight: 500;color: var(--disabledColor)}

/*subLine*/
.subLine{display: block;height: 6px;background: #F3F3F3;width:100%}
.subLine.pageFull{width: 100vw;transform: translateX(-20px)}

/*category*/
.categoryBox{width: 100vw;margin: 15px 0 15px -20px;padding: 0 20px;display: flex;overflow-x: auto}
.categoryItem{padding: 3px 8px;white-space: nowrap;display: inline-block;color: #ACACAC;font-size: 13px;font-weight: 700;border-radius: 43px}
.categoryItem.active{background: #FF7AB7;color: var(--mainTextColor);font-weight: 800}

/*accordion*/
.accordionTitleBox{width: calc(100% - 40px);margin: 0 auto;border-bottom: 1px solid #303036;align-items: center;padding: 20px 0;display: flex}
.accordionBox.active .accordionTitleBox{border: 0}
.accordionIcon{color: #6E6E77;font-size: 14px;font-weight: 700;min-width: 14px;text-align: left;line-height: 1.8}
.accordionBox.active .questionIcon{color: var(--mainTextColor)}
.accordionTextBox{display: flex;align-items: flex-start;width: calc(100% - 5px - 11px);padding-right: 16px}
.accordionTitle{text-align: left;width: 100%;font-size: 14px;font-weight: 600;line-height: 1.8}
.accordionTextBox + img{min-width: 11px;transition: transform 0.2s,opacity 0.2s;opacity: 0.5}
.accordionBox.active .accordionTextBox + img{transform: rotate(180deg);opacity: 1}
.answerText{background: #222225;padding: 20px 20px 30px}
.accordionText{font-size: 13px;line-height: 20px;color: rgba(255, 255, 255, 0.9)}
.answerText .accordionTextBox{padding: 0;width: 100%}

/*input*/
.input_section{padding: 0 20px 50px;width: 100%}

.inputButtonBox{display: flex;width: 100%;align-items: flex-start;gap: 8px;}
.inputButtonBox .inputItemBox{width: -webkit-fill-available;}
.inputButtonBox button{width: 44px;height: 44px;margin-top: 25px}
.inputSelectBox{display: flex;width: 100%;align-items: flex-start;gap: 8px;}
.inputSelectBox .inputItemBox:first-child{width: 30%;}
.inputSelectBox .inputItemBox:last-child{width: 70%;}
.inputItemBox{margin: 20px 0 0;position: relative}
.inputItemBox.labelInput.active .inputItem {padding: 22px 12px 4px;}
.inputItemBox:first-child{margin-top: 0}
.inputItemBox.marginUp{margin: 30px 0 0}
.inputName{position: absolute;z-index: 1;left: 14px;top: 50%;transform: translate(-3px,calc(-50% - 1px));font-size: 14px;color: #909AA4;opacity: 0;transition: opacity 0.1s, font-size 0.1s, top 0.1s, transform 0.1s;}
.inputItemTitle{color: #191B1D;font-size: 13px;font-weight: 700;line-height: 150%;margin-bottom: 8px;width: 100%;display: flex;justify-content: space-between;}
.inputItemTitle input[type="checkbox"]{display: block;-webkit-appearance: revert;}
.inputItemTitle .checkboxItem{display: flex;gap: 5px;}
.inputItemBox:not(.active) .inputName{animation: inputOut 0.1s forwards;font-size: 14px;top: 50%;opacity: 0;}
.inputItemBox.active .inputName{opacity: 1;display: block;font-size: 11px;top: 16%;transform: translate(0,5px);}
.inputItem{box-shadow: 0 1px 5px 0 rgba(0,0,0,0.1);background: var(--white, #FFF);border: 1px solid transparent;border-radius: 10px;width: 100%;padding: 13px 12px;font-size: 13px;color: var(--mainTextColor);height: 46px;}
.inputSet.search::after{content: "";width:24px;height:24px;position: absolute;background: url(/assets/images/icon/search.svg) no-repeat center;top: 50%;transform: translateY(-50%);right: 12px}
.inputItemBox.active .notName .inputItem{padding: 13px 15px}
.err .inputItem{animation: err 0.3s;border-color: #F44D4D}
.selItem .inputItem{color: #1E1E1E;background: url(/assets/images/basic/sel_icon.svg) no-repeat center right 0;background-color: var(--mainTextColor);padding-right: 40px}
.selItem .inputItem:disabled{color: #838383;background-color: rgba(0,0,0,0.1)}
.inputSet.pwBox .inputItem{padding-right: 50px}
.btn_pwCh{position: absolute;right: 8px;top: 50%;transform: translateY(-50%)}
.inputComBox{position: relative}
.inputComBox .inputItem{padding-right: 90px}
.btn_inputSubBtn{min-width: 67px;min-height: 26px;background: #c7c7c7;border-radius: 49px;color: var(--mainTextColor);font-size: 13px;padding: 4px;font-weight: 700;position: absolute;right: 13px;top: 50%;transform: translateY(-50%)}
.btn_inputSubBtn.pBg{background: #FF7AB7}
.cetifiedTimer{font-size: 13px;font-weight: 500;color: var(--mainTextColor);position: absolute;right: 15px;top: 50%;transform: translateY(-50%)}
.cetifiedInput .inputItem{padding-right: 122px}
.errMsg{color: #F04C41;margin: 4px 0 0;font-size: 12px;font-weight: 500}
.inputMsgBox .errMsg{margin: 0}
.inputMsgBox{margin: 4px 0 0;display: flex;gap: 10px;justify-content: space-between}
.inputMsgBox.contentEnd{justify-content: flex-end}
.btn_inputSubItem{font-size: 12px;font-weight: 500;color: var(--mainTextColor);text-decoration: underline;text-align: right}
.inputCaption{margin: 4px 0 0;font-size: 12px;color: rgba(255, 255, 255, 0.6);font-weight: 500}
.inputSet{position: relative}
.notReadOnlyColor .inputItem{color: #1e1e1e;background: #fff}

.inputUnitComBox .inputItem{padding-right: 85px}
.inputUnit{font-size: 15px;font-weight: 700;color: #5D5D5D;position: absolute;top: 50%;transform: translateY(-50%);right: 15px}

.inputBox.selectIn .inputItem{padding-left: 115px}
.inputBox.selectIn .inputItem + .inputItemBox{width: 90px;margin: 0;position: absolute;top: 50%;left: 15px;transform: translateY(-50%)}
.inputItemBox.active .inputBox.selectIn .inputItem + .inputItemBox{transform: translateY(calc(-50% + 7px))}
.inputBox.selectInItem .inputItem{font-size: 11px;padding: 2px 6px !important;border-radius: 5px;background-color: #6E6E77;border-color: #6E6E77;color: var(--mainTextColor);display: block}
.inputBox.selectInItem .selItem .inputItem{background-size: 20px;background-position: center right 5px}
.inputBox.selectInItem .selItem .inputItem::placeholder{color:#fff}

.chk_item{padding: 2px 0;display: flex;align-items: center;justify-content: space-between;gap: 15px}
.chk_item:not(.allChkItem) + .chk_item{margin: 16px 0 0}
.chk_item.allChkItem .chk_item_text{font-weight: 600;font-size: 15px}
.chk_item label{position: relative;padding: 0 0 0 30px;width: calc(100% - 34px);font-weight: 700}
.chk_item label::before{content: "";position: absolute;left: 0;top: 50%;transform: translateY(-50%);width: 20px;height: 20px;background: url(/assets/images/basic/chk_off.svg) no-repeat center;background-size: 100%}    
.chk_item input:checked + label::before{background-image: url(/assets/images/basic/chk_on.svg)}
.chk_item_text{color: var(--mainTextColor);font-size: 13px}
.chkBoxBtn{color:#6E6E77;text-decoration: underline;font-size: 13px;text-align: right;white-space: nowrap}
.inputItemBox + .chk_item{margin-top: 16px}
.inputItemBox.col2{width: 50%;}

.registerSection {gap: 26px;padding: 20px 20px 20px 20px;}

.textareaItem{display: block;resize: none;min-height: 180px;border: 1px solid transparent;border-radius: 10px;width: 100%;padding: 12px 15px;font-size: 14px;color: var(--mainTextColor);box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);}
.textareaCount{font-size: 13px;width: 100%;text-align: right;padding: 8px 0 0;color: #ACACAC}
.textAreaBtnComBox{position: relative}
.textAreaBtnComBox .textareaItem{background: #fff;color: #1E1E1E;padding-right: 90px}
.textAreaBtnComBox .btn_inputSubBtn{top: 14px;transform: translateY(0)}

.inputFileList{width: 100vw;transform: translateX(-20px);padding: 0 20px;overflow-x: auto;display: flex;gap: 12px}
.inputFileBox .inputFileList label{min-width: 70px;width: 70px;height: 70px;padding: 0;border-radius: 14px;display: flex;align-items: center;justify-content: center;background: #303036}
.fileItemMultipleItem{background-repeat: no-repeat;background-size: cover;background-position: center;width: 70px;height: 70px;min-width: 70px;border-radius: 14px;position: relative}
.fileItemMultipleItemDel{position: absolute;top:5px;right:5px;z-index: 1}

.react-datepicker-wrapper{width: 100%}
.datePicerInputBox .inputItem{width: 100%;background: url(/assets/images/basic/cal.svg) no-repeat right 15px center;background-color: var(--mainTextColor);color: #1E1E1E}

.inputSubCaptionItem{position: relative}
.inputSubCaptionItem .inputItem{padding-left: 33px}
.inputSubCaption_text{font-size: 15px;position: absolute;left: 15px;top: 50%;transform: translateY(-50%)}

.inputGroup{margin-top: 10px !important;}

.customSelectInputBox{position: relative}
.customSelectInputBox.active::after{transform: translateY(-50%) rotate(180deg)}
.customSelectInputBox::after{content: "";width:11px;height:6px;position: absolute;background: url(/assets/images/basic/select.svg) no-repeat center;top: 50%;transform: translateY(-50%);right: 12px}
.customSelectInputBox .inputItem{color: var(--mainTextColor);padding-right: 34px}
.customSelect_optionBoxWrapper.active{position: fixed;top: 0;left: 0;width: 100%;height: 100%;background-color: rgba(0, 0, 0, 0.57);z-index: 1001;transition: all 0.3s;}
.customSelect_optionBox{width: 100%;bottom: 0px;max-height: 40%;border-radius: 30px 30px 0px 0px;padding-bottom: 10px;display: block;background: #FFF;border: 1px solid #FFF;color: var(--btnTextColor);display: none;font-weight: 700;font-size: 14px;z-index:99;position: fixed;left: 0;overflow: auto;overflow-x: hidden;overflow-y: scroll;}
.customSelect_optionBox.active{display: block;}
.customSelect_option{display: flex;width: 100%;min-height: 40px;padding: 12px 20px;align-items: center;gap: 10px;color: #191B1D;font-size: 14px;font-weight: 500;line-height: 160%;}
.customSelect_optionContents{flex: 1 0 0;text-align: left;}
.customSelect_option_close{display: flex;color: #0094FF;position: absolute;right: 20px;top: 16px;}
.customSelect_option.active{background: rgba(0, 148, 255, 0.05);}
.customSelect_optionCheck{display: none;}
.customSelect_option.active .customSelect_optionCheck{display: block;}
.customSelect_option.active .customSelect_optionContents{color: #0094FF;}

.inputSubBox{display: flex;gap: 15px;align-items: flex-start;justify-content: space-between}
.inputSubBox .inputItemBox{width: calc(50% - 7.5px)}

.inputSubBoxOnly{margin: 24px 0 0}
.inputName.pageName{animation: none;transform: translate(0,0);position: static;color: var(--mainTextColor);font-size: 14px;font-weight: 700;opacity: 1}
.inputName.pageName + .inputItemBox{margin-top: 8px}

.inputViewBox{margin: 8px 0 0}
.inputViewText{border: 1px solid transparent;border-radius: 8px;width: 100%;padding: 13px 15px;font-size: 14px;color: var(--mainTextColor);background: #303036}
.inputViewBox.textAreaViewBox .inputViewText{display: block;resize: none;min-height: 180px;border-radius: 10px;padding: 12px 15px}
.fileViewBox{margin: 8px 0 0;width: 100vw;transform: translateX(-20px);padding: 0 20px;overflow-x: auto;display: flex;gap: 12px}
.fileViewBox>div{width: 70px;height: 70px;min-width: 70px;border-radius: 14px;position: relative}

/*member*/
.loginContetns{display: flex;align-items: center;flex-direction: column;justify-content: space-between;min-height: calc(var(--vh, 1vh)* 100);padding: 100px 20px 20px;gap: 30px;background: #F5F7FB;}
.loginSection{width: 100%;}
.logoBox{margin: 20px auto 70px;}
.logoBox img{margin: 0 auto}

.companyBtn{border-radius: 14px;width: 100%;display: flex;text-align: left;justify-content: space-between;align-items: center;box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);padding: 20px;gap: 15px;}
.companyBtn + .companyBtn{margin: 16px 0 0;}
.companyBtnTextBox{display: flex;align-items: center;gap: 8px;width: 100%;}
.stateItem{border-radius: 4px;padding: 2px 4px;background: var(--disabledBg);color: var(--disabledColor);font-size: 12px;font-weight: 600;white-space: nowrap;}
.companyBtnText{font-size: 14px;font-weight: 500;}

/*datepicker custom*/
.react-datepicker__month-container{width: 100%;}

.react-datepicker__input-container::after, .inputItem[type="date"]::after{content: "";width:24px;height:24px;position: absolute;background: url(/assets/images/icon/calendar.svg) no-repeat center;top: 50%;transform: translateY(-50%);right: 12px}
.react-datepicker__input-container .inputItem[readonly]{background-color: #FFF !important;}
.react-datepicker__input-container .inputItem[disabled]{background-color: #F7F9FC !important;}

    /*profile*/
.profileBoxTitle{color: #19181D;font-size: 15px;font-weight: 700;line-height: 15px;padding: 27px 0 20px 20px;}
.profileBox{width: 100%;height: 100%;display: flex;flex-direction: column;align-items: flex-start;padding: 0 20px 20px 20px;padding-bottom: 10px;overflow: auto;overflow-y: scroll;overflow-x: hidden;}
.profileItem{width: 100%;display: flex;align-items: center;font-size: 13px;font-weight: 500;line-height: 13px;padding: 15px 0 15px 0;border-bottom: 1px solid #F5F7FB;}
.profileItem .title{color: #909AA4;width: 35%;}
.profileItem .value{color: #191B1D;}

/*workHistory*/
.workButton{color: #FFF;font-size: 15px;font-weight: 700;line-height: 15px;display: flex;width: 100%;justify-content: center;align-items: center;gap: 4px;}
.workButton .sub{color: rgba(255, 255, 255, 0.80);font-weight: 400;letter-spacing: -0.06px;}
.workHistory .inputItem:disabled{color: #5F656B;background: #F7F9FC;box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);}

/*receipt*/
.receipt-box{width: 100%;height: 100%;display: flex;flex-direction: column;align-items: flex-start;padding: 36px 20px 20px 20px;overflow: auto;overflow-y: scroll;overflow-x: hidden;}
.receipt-item{display: flex;justify-content: space-between;width: 100%;padding-bottom: 14px;}
.receipt-item .label{color: #909AA4;font-size: 13px;font-weight: 500;line-height: 13px;}
.receipt-item .contents{color: #191B1D;text-align: right;font-size: 13px;font-weight: 500;line-height: 13px;}
.receipt-item.title .label{color: #191B1D;}
.receipt-item.title .contents{font-size: 15px;font-weight: 700;}

/*margin*/
.p-0{padding: 0 !important;}
.m-0{margin: 0;}
.mt-0{margin-top: 0 !important;}
.mt-10{margin-top: 10px;}
.mt-20{margin-top: 20px;}
.mb-10{margin-bottom: 10px !important;}
.mb-20{margin-bottom: 20px !important;}

.scroll{overflow-y: scroll;overflow-x: hidden;}

.hyphen{position: relative;top: 15px;}

.imagePopupSetion{position: absolute;top: 0;left: 0;width: 100%;height: 100%;padding: 64px 0 0;}
.imagePopupSetion.imagePopupBtnSetion{padding-bottom: 50px;}
.btn_imagePopupClose{width: 18px;height: 18px;position: absolute;right: 20px;top: 16px;}
.btn_imagePopupClose img{width: 100%;}
.imagePopup_imgArea{height: 100%;padding: 0 20px 30px;overflow-y: auto;}
.imagePopup_imgArea img{max-width: 100%;width: 100%;}
.imagePopup .popup_btnBox{position: fixed;bottom: 0;left: 0;}
.imagePopup .btn_popup{width: 100%;height: 55px;display: block;background: var(--btnBg_0);border: 1px solid var(--btnBg_0);color: var(--btnTextColor);font-weight: 700;font-size: 14px;border-radius: 0;padding: 5px 10px;position: sticky;z-index: 1;}

.signClear{font-size: 14px;position: absolute;bottom: 15px;right: 15px;color: #BDC6CF;text-decoration: underline;font-weight: 500}

/*scheduleList*/
.scheduleList_section{padding: 20px 20px 40px;}
.schedule_confirm_err{margin: 0 0 20px;background: #FFF1F1;border-radius: 30px;display: flex;align-items: center;justify-content: center;gap: 6px;color: #FF5F5F;font-size: 12px;font-weight: 600;padding: 8px 10px;}
.scheduleList_item{box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);border-radius: 14px;padding: 20px 16px;}
.scheduleList_item + .scheduleList_item{margin: 16px 0 0;}
.scheduleList_item_title{font-size: 14px;}
.scheduleList_item_title span{font-weight: 600;margin: 0 3px 0 0;}
.work_scheduleData{margin: 12px 0 0;border-radius: 8px;background: #F5F7FB;display: flex;align-items: center;justify-content: space-between;gap: 15px;padding: 11px 15px;}
.work_scheduleData_name{color: #909AA4;font-size: 13px;font-weight: 500;}
.work_scheduleData_value{font-size: 13px;font-weight: 500;}

.termsBox{width: 100%;text-align: center;font-size: 13px;font-weight: 500;line-height: 13px;padding: 20px 0 20px 0;color: #909AA4;}
.termsBox a{color: #909AA4;}

/*반응형*/
@media (max-width:1800px){

}
@media (max-width:1680px){
	
}
@media (max-width:1600px){

}
@media (max-width:1440px){

}
@media (max-width:1366px){

}
@media (max-width:1280px){

}
@media (max-width:1152px){

}
@media (max-width:1024px){

}
@media (max-width:960px){
    
}
@media (max-width: 720px){
    
}
@media (max-width: 650px){
    
}
@media (max-width: 550px){
}
@media (max-width: 490px){
}
@media (max-width: 400px){
}
@media (max-width: 340px){
}
/*//*/