.popup_cover{width: 100%;height: 100%;background: rgba(0, 0, 0, 0.6);opacity: 0;transition: opacity 0.2s}

/*popup*/
.popup{position: fixed;top: 0;left: 0;width: 100%;height: 100%;z-index: 9000;display: none}
.popup .popupSetion{padding: 0;overflow: hidden;position: fixed;background: #4C4C53;top: 50%;left: 50%;transform: translate(-50%,-50%);z-index: 1;border-radius: 18px;width: calc(100% - 40px);max-width: 300px;max-height: calc(100% - 40px);opacity: 0;transition: opacity 0.2s}
.popupTextBox{padding: 20px}
.btnPopupSetion .popupSetion{padding-bottom: calc(62px + 1.313em + 20px)}
.popup.active .popup_cover{opacity: 1}
.popup.active .popupSetion{opacity: 1}
.popupTitle{font-weight: 700;font-size: 16px;margin: 0 0 14px;color:#fff}
.popupTitle>p{display: inline-block;margin: 0 0 0 6px;background: #303036;border-radius: 5px;padding: 2px 6px;font-size: 11px;color: rgba(255, 255, 255, 0.6);transform: translateY(-1px)}
.popupTitle>p>span{color: rgba(255, 255, 255, 0.9)}
.popupText{font-size: 14px;color: rgba(255, 255, 255, 0.8)}
.popupText.rColor{color: #F44D4D}
.popupText .rColor{color: #F44D4D}
.popup_btnBox{display: flex;width: 100%;justify-content: space-between;border-top: 1px solid rgba(255, 255, 255, 0.1)}
.btn_popup{color: #fff;height: 52px;display: flex;align-items: center;justify-content: center;font-size: 13px;font-weight: 600;width:100%}
.btn_popup:first-child:nth-last-child(2),.btn_popup:first-child:nth-last-child(2) ~ .btn_popup{width: 50%}
.btn_popup + .btn_popup{border-left: 1px solid rgba(255, 255, 255, 0.1)}
.btn_popup[disabled=true]:not(.btn_popup[disabled=false]),.btn_popup:disabled:not(.btn_popup[disabled=false]){background: #D8D8D8;border-color: #d8d8d8;cursor: auto}
.btn_popup.line[disabled=true]:not(.btn_popup[disabled=false]),.btn_popup.line:disabled:not(.btn_popup[disabled=false]){background: #fff;border-color: #C7C7C7;color: #C7C7C7}

/*rightPopup*/
.rightPopupClose{position: absolute;top: 50%;right: 20px;transform: translateY(-50%)}
.rightPopup{background: #19191B;position: fixed;top: 0;right: 0;width: 100%;height: 100%;transform: translateX(100%);z-index: 9000;transition: transform 0.2s cubic-bezier(0.89, 0.11, 0.09, 0.89)}
.rightPopup.active{transform: translateX(0)}
.rightPopup .pageBody{overflow: auto;overflow-y: scroll;overflow-x: hidden;}
.rightPopup .popup_cover{display: none}
.rightPopup .menuBox{display: inline-flex;width: 100%;height: 100%;padding-top: 16px;flex-direction: column;align-items: flex-start;gap: 5px;flex-shrink: 0;margin-bottom: 90px;}
.rightPopup .menuBox .item{display: flex;cursor: pointer;width: 100%;padding: 13px 20px;align-items: center;gap: 12px;border-radius: 20px 20px 0px 0px;background: #FFF;color: #191B1D;font-size: 15px;font-weight: 600;line-height: 15px;}
.rightPopup .card{display: flex;padding: 14px 16px;align-items: center;gap: 10px;flex-direction: revert;}
.rightPopup .card > div:first-child{display: flex;flex-direction: column;align-items: flex-start;gap: 12px;flex: 1 0 0;width: 80%;}
.rightPopup_section{height: 100%;background: #19191B}
.rightPopup_header_section{height: 54px;position: relative;padding: 0 20px;display: flex;align-items: center;z-index: 10}
.rightPopup_contents{height: calc(100% - 54px);overflow-y: auto;padding: 0}
.rightPopup_title{font-size: 14px;font-weight: 500;padding: 0 25px;display: flex;width: 100%;height: 54px;align-items: center;text-align: center;justify-content: center;letter-spacing: -1px;line-height: 1.2}
.termsText{padding: 20px;font-size: 15px;line-height: 25px;white-space: pre-wrap}
.rightPopup{background: rgba(53, 53, 53, 0.9);backdrop-filter: blur(5.5px);-webkit-backdrop-filter: blur(5.5px)}

.rightPopup.bottomBtnText .rightPopup_contents{padding-bottom: 160px}
.rightPopup.bottomBtn .rightPopup_contents{padding-bottom: 60px}
.rightPopup.sidePadding .rightPopup_contents{padding-left: 20px;padding-right: 20px}

/*selPopup*/
.selPopup{position: fixed;top: 0;left: 0;width: 100%;height: 100%;z-index: 9000;display: none}
.selPopupSetion{border-top-left-radius: 20px;border-top-right-radius: 20px;padding: 20px 0 0;background: #303036;position: absolute;bottom: 0;left: 0;z-index: 1;width: 100%;transform: translateY(100%);transition: transform 0.2s cubic-bezier(0.13, 0.91, 0.11, 0.91)}
.selPopupTextBox{padding: 0 20px 10px}
.selPopup_selBox{padding: 0 0 30px;max-height: 300px;overflow-y: auto}
.selPopup_sel{color: rgba(255, 255, 255, 0.9);padding: 10px 20px;text-align: left;border-radius: 0;border: 0;display: block;width: 100%;font-size: 14px;font-weight: 400}
.selPopup.active .popup_cover{opacity: 1}
.selPopup.active .selPopupSetion{transform: translateY(0)}
.selPopup_sel.active{font-weight: 600;background: url(/assets/images/basic/selPopup_active.svg) no-repeat center right 20px}
.selPopup .popupHaeder{position: relative;padding: 0 0 14px}
.selPopup .popupHaederTitle{font-size: 16px;font-weight: 700;color: #fff;padding: 0 20px}
.popupClose{position: absolute;right: 20px;top: -2px}
.selPopup.selBgType .selPopup_sel.active{background-color:rgba(34, 34, 37, 1)}

/*toastPopup*/
.toastPopup{position: fixed;top: 0;left: 0;width: 100%;height: 100%;z-index: 9000;display: none}
.toastPopup .popupSetion{border-top-left-radius: 30px;border-top-right-radius: 30px;display: flex;flex-direction: column;max-height: calc(100% - 20px);background: #fff;position: absolute;bottom: 0;left: 0;z-index: 1;padding: 20px 20px 85px;width: 100%;transform: translateY(100%);transition: transform 0.2s cubic-bezier(0.13, 0.91, 0.11, 0.91)}
.toastPopup.active .popup_cover{opacity: 1}
.toastPopup.active .popupSetion{transform: translateY(0)}
.toastPopup.active.notBtn .popupSetion{padding-bottom: 30px}
.toastPopup .popupHaeder{min-height: 52px;position: relative;display: flex;align-items: center;justify-content: space-between;gap: 15px}
.toastPopup .popupHaederTitle{text-align: left;font-size: 16px;font-weight: 700;color: #fff;display: flex;align-items: center;gap: 6px;}
.toastPopup .popupContents{overflow-y: auto}
.toastPopup .popupClose{position: absolute;right: 0;top: -2px}

.toastPopup_btnBox{position: absolute;left: 0;bottom: 0;width: 100%;min-height: 55px;border: 0;justify-content: space-between}
.toastPopup_btnBox .btn_popup{width: 100%;height: 55px;background: var(--mainColor);border: 1px solid var(--mainColor);color: #0B0B0C;font-weight: 700;font-size: 15px;border-radius: 0;padding: 5px 10px;display: flex;align-items: center;justify-content: center}
.popup_btnChkBox{min-height: 20px;margin: 20px 0 0}
.popup_btnChkBox + .toastPopup_btnBox{margin-top: 20px}

.toastBasicContents{padding: 0 0 20px}
.toastBasicContents_title{font-size: 16px;font-weight: 700}
.toastBasicContents_text{font-size: 13px;color: rgba(255, 255, 255, 0.9);margin: 2px 0 0}

.toastBasicContents_subItemCom{display: flex;align-items: flex-start;justify-content: space-between;gap: 15px;}
.toastBasicContents_title{font-size: 18px;font-weight: 700;}
.toastBasicContents_text{color: #404244;margin: 3px 0 0;font-size: 14px;}
.toastBasicContents_subItem{font-size: 14px;font-weight: 600;white-space: nowrap;min-width: 26px;}
.toastBasicContents_subItem span{color: #0094FF;}
.signArea{position: relative;height: 120px;background: #F5F7FB;border-radius: 10px;overflow: hidden;}

/*slidePopup*/
.slideUpPopup{position: fixed;top: 70px;left: 0;width: 100%;height: calc(100% - 130px);background: var(--mainColor);box-shadow: 0px 0px 16px rgb(0 0 0 / 20%);border-radius: 20px 20px 0px 0px;z-index: 1002;transform: translateY(calc(100% - 82px));transition: background 0.2s}
.slideUpPopup.active{transition: background 0.2s, transform 0.395s}
.slideUpPopup.open{background: #303036}
.scroll_sel_icon{width: 100%;height: 30px;display: flex;align-items: center;justify-content: center}
.slideUpPopup.open .scroll_sel_icon img{transition: transform 0.2s;transform: rotate(180deg)}
.slideUpPopup.open .slideUpPopup_titleBox{opacity: 0;z-index: 1;animation: upTitleBox 0.2s 0s forwards}
.slideUpPopup_titleBox{display: flex;align-items: center;transition: opacity 0.2s;opacity: 1;background: var(--mainColor);position: relative;width: 100%;z-index: 3;justify-content: space-between;gap: 10px;min-height: 50px}
.slideUpPopup_contents{position: absolute;left: 0;width: 100%;height: calc(100% - 30px);top: 30px;overflow-y: auto;z-index: 2;background: transparent;opacity: 0;transition: opacity 0.2s}
.slideUpPopup.open .slideUpPopup_contents{opacity: 1}
.slideUpPopup_cover{background: rgba(0, 0, 0, 0.6);position: fixed;top: 0;left: 0;width: 100%;height: 100%;z-index: 1001;display: none;opacity: 0;transition: opacity 0.2s}
.slideUpPopup_cover.active{display: block;opacity: 1}